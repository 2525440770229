import React, { useEffect, useState } from 'react';
import axios from "axios";
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SpinnerS from '../components/SpinnerS';

function Home() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const [cvList, setCvList] = useState([]);
    const getCvListWithUser = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCVsWithUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            setCvList(res.data);
        })
    }
    const fisnishCvs = cvList.filter(item => item.status_name === 'Fisnish');
    const percent = Intl.NumberFormat('en-US').format(fisnishCvs.length/cvList.length*100);
    const [cvStatus, setCvStatus] = useState([]);
    const getCvStatus = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCvStatus?id=`)
        .then((res)=>{
            setCvStatus(res.data);
        })
    }
    const [docs, setDocs] = useState([]);
    const getDocs = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getDocsActive`)
        .then((res)=>{
            setDocs(res.data);
        })
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getCvListWithUser();
            getCvStatus();
            getDocs();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
    <>
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>

                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>

                        {/* <!-- Begin Page Content --> */}
                        <div className="container-fluid">

                            {/* <!-- Page Heading --> */}
                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                                {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
                                    <i className="fas fa-download fa-sm text-white-50"></i> 
                                    Generate Report
                                </a> */}
                            </div>

                            {/* <!-- Content Row --> */}
                            <div className="row">

                                {/* <!-- Earnings (Monthly) Card Example --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-primary shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                        Earnings (Monthly)</div>
                                                    {/* <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div> */}
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Earnings (Monthly) Card Example --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-success shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2">
                                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                        Earnings (Annual)</div>
                                                    {/* <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div> */}
                                                </div>
                                                <div className="col-auto">
                                                    <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Earnings (Monthly) Card Example --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-info shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col mr-2 ml-2">
                                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Rate
                                                    </div>
                                                    <div className="row no-gutters align-items-center ">
                                                    {
                                                        cvList.length > 0 ?
                                                        <div className="col-auto ml-2">
                                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{percent}%</div>
                                                        </div>
                                                        :
                                                        <div className="col-auto ml-2">
                                                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">0%</div>
                                                        </div>
                                                    }
                                                        {/* <div className="col">
                                                            <div className="progress progress-sm mr-2">
                                                                <div className="progress-bar bg-info" role="progressbar"
                                                                    style={{width: {percent}}} aria-valuenow="50" aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-auto mr-2">
                                                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Pending Requests Card Example --> */}
                                <div className="col-xl-3 col-md-6 mb-4">
                                    <div className="card border-left-warning shadow h-100 py-2">
                                        <div className="card-body">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col ml-2">
                                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">CV Complete</div>
                                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{fisnishCvs.length}</div>
                                                </div>
                                                <div className="col-auto mr-2">
                                                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Content Row --> */}

                            <div className="row">

                                {/* <!-- Area Chart --> */}
                                <div className="col-xl-8 col-lg-7">
                                    <div className="card shadow mb-4">
                                        {/* <!-- Card Header - Dropdown --> */}
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-black">Danh sách hồ sơ đang xử lý ({cvList.length})</h6>
                                            {/* <div className="dropdown no-arrow">
                                                <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenuLink">
                                                    <div className="dropdown-header">Dropdown Header:</div>
                                                    <a className="dropdown-item" href="#">Action</a>
                                                    <a className="dropdown-item" href="#">Another action</a>
                                                    <div className="dropdown-divider"></div>
                                                    <a className="dropdown-item" href="#">Something else here</a>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <!-- Card Body --> */}
                                        <div className="card-body">
                                            <div className="" style={{maxHeight: "500px", overflowY: "auto"}}>
                                            {
                                                cvList && cvList.length > 0 && cvList.map((item, index)=>(
                                                <a href={"/detail-cv/"+item.id} className="p-4 mb-2 bg-light border rounded text-decoration-none d-block"  key={index}>
                                                    {/* <a href={"/detail-job/"+item.id} className="text-decoration-none"><h5>{item.job_name}</h5></a> */}
                                                    <div className="mt-1">
                                                        <h6 className="m-0 mt-2"><i className="fa-regular fa-user mr-2"></i>{item.fullname}</h6>
                                                        <h6 className="m-0 mt-2"><i className="fa-solid fa-phone mr-2"></i>{item.phone}</h6>
                                                        <h6 className="m-0 mt-2"><i className="fa-regular fa-envelope mr-2"></i>{item.email}</h6>
                                                        <h6 className="m-0 mt-2"><i className="fa-solid fa-spinner mr-2"></i>{item.status_name}</h6>
                                                    </div>
                                                </a>
                                                ))
                                            }
                                            {
                                                !cvList || cvList.length <= 0 &&
                                                <div>
                                                    <div className='mt-1'>
                                                        {
                                                            <SpinnerS/>
                                                        }
                                                    </div>
                                                    <div className='mt-1'>
                                                        {
                                                            <SpinnerS/>
                                                        }
                                                    </div>
                                                    <div className='mt-1'>
                                                        {
                                                            <SpinnerS/>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Pie Chart --> */}
                                <div className="col-xl-4 col-lg-5">
                                    <div className="card shadow mb-4">
                                        {/* <!-- Card Header - Dropdown --> */}
                                        <div
                                            className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-black">Các tình trạng hồ sơ</h6>
                                            {/* <div className="dropdown no-arrow">
                                                <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenuLink">
                                                    <div className="dropdown-header">Dropdown Header:</div>
                                                    <a className="dropdown-item" href="#">Action</a>
                                                    <a className="dropdown-item" href="#">Another action</a>
                                                    <div className="dropdown-divider"></div>
                                                    <a className="dropdown-item" href="#">Something else here</a>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <!-- Card Body --> */}
                                        <div className="card-body">
                                            <div className="" style={{minHeight: "500px", overflowY: "auto"}}>
                                            {
                                                cvStatus.length > 0 && cvStatus.map((item, index)=>(
                                                <div className="mr-2" key={index}>
                                                {
                                                    item.status_name == 'Fisnish' ?
                                                    <p className='text-success'><i className="fas fa-circle"></i> {item.status_name}</p>
                                                    :
                                                    item.status_name == 'Stop' ?
                                                    <p className='text-danger'><i className="fas fa-circle"></i> {item.status_name}</p>
                                                    :
                                                    item.status_name == 'Not Start' ?
                                                    <p className='text-primary'><i className="fas fa-circle"></i> {item.status_name}</p>
                                                    :
                                                    <p className='text-warning'><i className="fas fa-circle"></i> {item.status_name}</p>
                                                }
                                                </div>
                                                       
                                                ))
                                            }
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Content Row --> */}
                            <div className="row">

                                <div className="col-lg-12 mb-4">

                                    {/* <!-- Project Card Example --> */}
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-black">Tài liệu Training</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="row justify-content-center">
                                            {
                                                docs && docs.length > 0 && docs.map((item, index)=>(
                                                <div className="col-4 mt-5" key={index}>
                                                    <a href={"../File/"+item.file_name} download={item.file_name} className='text-center text-decoration-none d-block'>
                                                        <i className="fa-solid fa-print text-black" style={{ fontSize: "1.8rem" }}></i>
                                                        <p><b>{item.file_name}</b></p>
                                                    </a>
                                                </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Color System --> */}
                                    {/* <div className="row">
                                        <div className="col-lg-6 mb-4">
                                            <div className="card bg-primary text-white shadow">
                                                <div className="card-body">
                                                    Primary
                                                    <div className="text-white-50 small">#4e73df</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <div className="card bg-success text-white shadow">
                                                <div className="card-body">
                                                    Success
                                                    <div className="text-white-50 small">#1cc88a</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <div className="card bg-info text-white shadow">
                                                <div className="card-body">
                                                    Info
                                                    <div className="text-white-50 small">#36b9cc</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <div className="card bg-warning text-white shadow">
                                                <div className="card-body">
                                                    Warning
                                                    <div className="text-white-50 small">#f6c23e</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <div className="card bg-danger text-white shadow">
                                                <div className="card-body">
                                                    Danger
                                                    <div className="text-white-50 small">#e74a3b</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <div className="card bg-secondary text-white shadow">
                                                <div className="card-body">
                                                    Secondary
                                                    <div className="text-white-50 small">#858796</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <div className="card bg-light text-black shadow">
                                                <div className="card-body">
                                                    Light
                                                    <div className="text-black-50 small">#f8f9fc</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <div className="card bg-dark text-white shadow">
                                                <div className="card-body">
                                                    Dark
                                                    <div className="text-white-50 small">#5a5c69</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>

                                <div className="col-lg-6 mb-4">

                                    {/* <!-- Illustrations --> */}
                                    {/* <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-primary">Illustrations</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="text-center">
                                                <img className="img-fluid px-3 px-sm-4 mt-3 mb-4" style={{width: "25rem"}}
                                                    src="img/undraw_posting_photo.svg" alt="..."/>
                                            </div>
                                            <p>Add some quality, svg illustrations to your project courtesy of <a
                                                    target="_blank" rel="nofollow" href="https://undraw.co/">unDraw</a>, a
                                                constantly updated collection of beautiful svg images that you can use
                                                completely free and without attribution!</p>
                                            <a target="_blank" rel="nofollow" href="https://undraw.co/">Browse Illustrations on
                                                unDraw &rarr;</a>
                                        </div>
                                    </div> */}

                                    {/* <!-- Approach --> */}
                                    {/* <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-primary">Development Approach</h6>
                                        </div>
                                        <div className="card-body">
                                            <p>SB Admin 2 makes extensive use of Bootstrap 4 utility classes in order to reduce
                                                CSS bloat and poor page performance. Custom CSS classes are used to create
                                                custom components and custom utility classes.</p>
                                            <p className="mb-0">Before working with this theme, you should become familiar with the
                                                Bootstrap framework, especially the utility classes.</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>

                    <Footer/>
                </div>
            </div>
        </div>
    </>
    )
}

export default Home
